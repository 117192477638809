<template>
  <div>
    <h2 class="text-center mb-4">Маршрутный лист</h2>
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>

    <v-card v-else class="pa-4 mb-4">
      <v-row>
        <v-col cols="12" md="3">
          <p class="subtitle-2 mb-0 font-weight-black">
            Название маршрута: {{ route.name }}
          </p>
          <p class="subtitle-2 mb-0 font-weight-black">
            Дата: {{ route.date }}
          </p>
          <p
            v-if="route.region && route.region.name"
            class="subtitle-2 mb-0 font-weight-black"
          >
            Регион: {{ route.region.name }}
          </p>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            @change="reassingVerifier($event, route)"
            :value="route.verifier_id"
            :items="
              filteredVerifiers.map((item) => ({
                value: item.id,
                text: item.name,
              }))
            "
            clearable
            label="Поверитель"
            hide-details
          ></v-select>
          <v-select
            @change="reassingMechanic($event, route)"
            :value="route.mechanic_id"
            :items="
              filteredMechanics.map((item) => ({
                value: item.id,
                text: item.name,
              }))
            "
            label="Слесарь"
            clearable
            hide-details
          ></v-select>
          <v-select :items="[]" clearable hide-details label="Водитель" />
        </v-col>
        <v-col cols="12" md="3">
          <p class="body-2 mb-0 font-weight-bold">
            Кол-во поверок:
            {{
              route.verifications &&
              route.verifications.filter((v) => v.job_type.id === 1).length
            }}
          </p>
          <p class="body-2 mb-0 font-weight-bold">
            Кол-во замен:
            {{
              route.verifications &&
              route.verifications.filter((v) => v.job_type.id === 2 || v.job_type.id === 3).length
            }}
          </p>
          <p class="body-2 mb-0 font-weight-bold">
            Всего: {{ route.verifications && route.verifications.length }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="d-flex flex-column justify-space-between"
        >
          <v-btn
            block
            color="green lighten-1 mb-2"
            class="mr-2"
            :href="`${host}/route/${route.id}/download_excel`"
            target="_blank"
            dark
            small
          >
            <v-icon small class="mr-2">
              mdi-microsoft-excel
            </v-icon>
            скачать в excel
          </v-btn>
          <v-btn
            block
            color="blue lighten-1 mb-2"
            :href="`${host}/route/${route.id}/download`"
            target="_blank"
            dark
            small
          >
            <v-icon small class="mr-2"> mdi-printer-outline </v-icon>
            распечатать по шаблону
          </v-btn>
          <v-btn
            block
            @click="editRouteDrawer = true"
            color="blue lighten-1 mb-2 d-flex"
            dark
            small
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
            отредактировать
          </v-btn>
          <v-btn
            block
            @click="remove"
            color="red lighten-1 mb-2 d-flex"
            dark
            small
          >
            <v-icon small class="mr-2">
              mdi-close
            </v-icon>
            удалить
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-skeleton-loader v-if="loading" type="table-tbody"></v-skeleton-loader>

    <template
      v-else-if="!loading && route.verifications && route.verifications.length"
    >
      <v-data-table
        :items="route.verifications"
        :hide-default-header="true"
        :show-select="false"
        :loading="false"
        fixed-header
        height="560px"
        hide-default-footer
        item-key="name"
        class="elevation-1"
        :items-per-page="30"
      >
        <template v-slot:header>
          <thead>
            <tr>
              <th :colspan="12">
                <div class="d-flex flex-wrap">
                  <v-btn
                    :disabled="!verificationSelected.length"
                    @click="reassignVerificationDialog = true"
                    color="green lighten-1"
                    class="mr-2"
                    small
                  >
                    <v-icon small class="mr-2">
                      mdi-application-export
                    </v-icon>
                    перенести в др. маршрут
                  </v-btn>
                  <v-btn
                    @click="unassignVerificationFromRoute"
                    :disabled="!verificationSelected.length"
                    color="red lighten-1"
                    class="mr-2"
                    small
                  >
                    <v-icon small class="mr-2"> mdi-close-box-outline </v-icon>
                    убрать с маршрутного листа
                  </v-btn>
                </div>
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Дата очередной поверки</th>
              <th>Дата проведения</th>
              <th>Тип</th>
              <th>Статус</th>
              <th>Заказчик</th>
              <th>Цена <br />(причина ручного изменения цены)</th>
              <th>Комментарий</th>
              <th>тел. заказчика</th>
              <th colspan="2"></th>
            </tr>
          </thead>
        </template>

        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="index"
              :style="{
                'background-color':
                  item.meter_status_id === 2 ? '#FFEBEE' : '#E8F5E9',
              }"
            >
              <td>
                <v-checkbox
                  v-model="verificationSelected"
                  :value="item.id"
                  class="mt-0"
                  hide-details
                />
              </td>
              <td>{{ item.check_date ? item.check_date : "не указано" }}</td>
              <td>
                {{
                  item.verification_date ? item.verification_date : "не указано"
                }}
              </td>
              <td>
                {{
                  item.type && item.type.name ? item.type.name : "не указано"
                }}
              </td>
              <td>
                {{
                  meter_statuses.find((ms) => ms.id === item.meter_status_id)
                    .name || null
                }}
              </td>

              <td>{{ item.city }}, {{ item.address }}</td>
              <td>
                {{ item.price }} <br />
                {{ item.price_comment }}
              </td>
              <td>{{ item.comment }}</td>
              <td>{{ item.phone }}</td>
              <td colspan="2">
                <v-btn
                  color="blue lighten-1"
                  icon
                  :disabled="!item.images.length"
                >
                  <v-icon> mdi-camera </v-icon>
                </v-btn>
                <v-btn
                  color="blue lighten-1"
                  icon
                  :href="`/manager/verifications/edit/${item.id}`"
                  target="_blank"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>

        <template v-slot:no-data> Нет данных! </template>

        <template v-slot:no-results> Нет результатов поиска! </template>
      </v-data-table>
    </template>

    <v-navigation-drawer v-model="drawer" absolute right temporary width="40%">
      <p>Функционал по ролям и визированию</p>
      <div class="d-flex align-center justify-space-between pa-2 px-4">
        <div class="d-flex align-center">
          <span class="body-2">Выполнено:</span>
          <v-badge inline left tile color="green lighten-1">
            <span class="body-2">метролог</span>
          </v-badge>
          <v-badge inline left tile color="green lighten-1">
            <span class="body-2">диспетчер</span>
          </v-badge>
          <v-badge inline left tile color="red lighten-1">
            <span class="body-2">бухгалтер</span>
          </v-badge>
        </div>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="editRouteDrawer"
      absolute
      right
      temporary
      width="40%"
    >
      <v-card class="pa-4" flat>
        <RouteForm :id="id" />
      </v-card>
    </v-navigation-drawer>

    <DeleteConfirmation
      :visible="deleteConfirmationVisible"
      v-on:confirm="removeConfirmed"
      v-on:decline="removeDeclined"
    />

    <v-dialog
      v-model="reassignVerificationDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Перенести в другой маршрут
          </span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="allRoutes.filter((r) => r.id !== route.id && r.region_id === route.region_id)"
            item-text="name"
            item-value="id"
            v-model="reassignedRouteId"
            label="Маршрутные листы"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-btn
            color="blue darken-1"
            text
            @click="reassignVerificationDialog = false"
          >
            Закрыть
          </v-btn>

          <v-btn
            :disabled="!reassignedRouteId"
            color="blue darken-1"
            text
            @click="reassignVerificationToRoute"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { HOST } from "@/config"
import { ListsService, UsersService, RoutesService, VerificationsService } from '@/common/api.service'
import DeleteConfirmation from "@/components/Modals/DeleteConfirmation"
import RouteForm from "@/components/Routes/Form"

export default {
  name: "Route",
  components: {
    RouteForm,
    DeleteConfirmation,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      drawer: false,
      editRouteDrawer: false,
      reassignVerificationDialog: false,
      reassignedRouteId: null,
      verificationSelected: [],
      route: {
        name: "",
        date: "",
        verifications: [],
      },
      regions: [],
      verifiers: [], // поверители
      mechanics: [], // механики
      meter_statuses: [],
      districts: [],
      allRoutes: [],
      routes: [],
      // Delete logic
      deleteConfirmationVisible: false,
      deleteVerificationId: null,
      host: HOST,
    }
  },
  methods: {
    //
    async reassignVerificationToRoute() {
      this.reassignVerificationDialog = false
      this.loading = true
      try {
        for (const id of this.verificationSelected) {
          await VerificationsService.updateRoute(id, this.reassignedRouteId)
        }
        const response = await RoutesService.get(this.id)
        this.route = { ...this.route, ...response.route }
        this.verificationSelected.splice(0)
      } finally {
        this.reassignedRouteId = null
        this.loading = false
      }
    },

    //
    async unassignVerificationFromRoute() {
      this.loading = true
      try {
        for (const id of this.verificationSelected) {
          await VerificationsService.updateRoute(id, null)
        }
        const response = await RoutesService.get(this.id)
        this.route = { ...this.route, ...response.route }
        this.verificationSelected.splice(0)
      } finally {
        this.loading = false
      }
    },

    /**
     * Привязывает запись слесаря к МЛ
     * @param {Number} mechanic_id - id слесаря
     * @param route
     */
    async reassingMechanic(mechanic_id, route) {
      this.loading = true
      try {
        await RoutesService.update(route.id, {
          ...route,
          mechanic_id: mechanic_id,
        })
        const response = await RoutesService.get(this.id)
        this.route = { ...this.route, ...response.route }
      } catch (e) {
        console.error("reassingMechanic err:", { e })
      } finally {
        this.loading = false
      }
    },

    /**
     * Привязывает запись поверителя к МЛ
     * @param {Number} verifier_id - id поверителя
     * @param route
     */
    async reassingVerifier(verifier_id, route) {
      this.loading = true
      try {
        await RoutesService.update(route.id, {
          ...route,
          verifier_id: verifier_id,
        })
        const response = await RoutesService.get(this.id)
        this.route = { ...this.route, ...response.route }
      } catch (e) {
        console.error("reassingVerifier err:", { e })
      } finally {
        this.loading = false
      }
    },

    // Нажата кнопка 'удалить'
    // Сохраняем ID и показываем предупреждение
    remove () {
      this.deleteVerificationId = this.id
      this.deleteConfirmationVisible = true
    },

    // Юзер подтвердил удаление
    removeConfirmed() {
      this.deleteConfirmationVisible = false
      this.loading = true
      try {
        RoutesService.delete(this.deleteVerificationId)
          .then(() => {
            this.$router.push('/manager/routes')
          })
        /*const response = */ // await RoutesService.get(this.id)
        // this.route = { ...this.route, ...response.route }
      } catch (e) {
        console.error("removeConfirmed err:", { e })
      } finally {
        this.loading = false
      }
    },

    // Юзер передумал удалять
    removeDeclined() {
      this.deleteConfirmationVisible = false
    }
  },

  computed: {
    //
    filteredMechanics() {
      if (this.route && this.route.region && this.route.region.id) {
        return this.mechanics.filter((m) => {
          return m.region_id === this.route.region.id
        })
      }
      return this.mechanics
    },

    //
    filteredVerifiers() {
      if (this.route && this.route.region && this.route.region.id) {
        return this.verifiers.filter((m) => {
          return m.region_id === this.route.region.id
        })
      }

      return this.verifiers
    },
  },

  beforeMount() {
    this.loading = true

    ListsService.get(["regions", "meter_statuses", "districts", 'routes']).then(
      ({ data }) => {
        this.regions = data.regions
        this.meter_statuses = data.meter_statuses
        this.districts = data.districts
        this.allRoutes = data.routes
      }
    )

    // Получить поверителей
    UsersService.workers(1).then(({ data }) => {
      this.verifiers = data.users
    })

    // Получить слесарей
    UsersService.workers(2).then(({ data }) => {
      this.mechanics = data.users
    })

    //
    RoutesService.get(this.id).then((response) => {
      this.route = response.route
      this.loading = false
    })

    //
    RoutesService.get().then((response) => {
      this.routes = response.routes
      this.loading = false
    })
  }
}
</script>
